export default function useScrollDirection() {
    const prevScrollPos = ref(0)
    const scrollingDirection = ref<string | null>(null)
    const scrollingStopped = ref(true)
    let scrollingTimeout: ReturnType<typeof setTimeout> | null = null
    const isScrolling = ref(false);

    const handleScroll = () => {
        const currentScrollPos = window.scrollY
        if (currentScrollPos > prevScrollPos.value) scrollingDirection.value = 'down'
        else if (currentScrollPos < prevScrollPos.value) scrollingDirection.value = 'up'

        prevScrollPos.value = currentScrollPos

        clearTimeout(scrollingTimeout as any)
        scrollingStopped.value = false
        scrollingTimeout = setTimeout(() => {
            scrollingStopped.value = true
        }, 200)
    }

    onMounted(() => window.addEventListener('scroll', handleScroll))
    onBeforeUnmount(() => window.removeEventListener('scroll', handleScroll))

    return {
        scrollingDirection,
        scrollingStopped
    }
}
