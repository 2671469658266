import {contentIcon, homeIcon, planeIcon} from "~/utils/icon-utils";

export const flightBreadcrumb = () => [{
    label: 'خانه',
    icon: homeIcon(),
    to: '/'
}, {
    label: 'پرواز',
    icon: planeIcon(),
    to: '/flight'
}]

export const flightPathBreadcrumb = (path: {fa_name: string, to: string}) => ([
    ...flightBreadcrumb(),
    {
        label: path.fa_name,
        icon: contentIcon(),
        to: `/flight${path.to}`
    }
])
