import {defineStore} from "pinia";
import type {ErrorInterface} from "~/types/ErrorInterface";
import type {ApiResponse} from "~/types/ApiResponse";
import type {FlightItem} from "~/types/flight/list/FlightItem";
import type {FlightListForm} from "~/types/flight/list/FlightListForm";


export const useFlightListStore = defineStore('flightList', {
    state: () => ({
        data: <FlightItem[]>[],
        flights: <FlightItem[]>[],
        closed: <FlightItem[]>[],
        form: <FlightListForm>{},
        loading: <boolean>false,
        flight_loading: <boolean>false,
        success: <string | null>null,
        errors: <ErrorInterface>{},
        cache_time: <number | null>null,
    }),
    getters: {
        isCacheExpired: (state): boolean => {
            return !(state.cache_time && Math.floor(Date.now() / 1000) < state.cache_time);
        },
    },
    actions: {
        async getFlights() {
            this.cache_time = null
            this.loading = true
            this.data = []
            try {
                const response = await useCustomFetch('/api/v1/flight/list', {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: this.form,
                }) as ApiResponse<FlightItem[]>
                if (!response.success) {
                    this.errors = {
                        message: response.message,
                        code: response.statusCode
                    }
                    return this.handleLoading()
                }
                this.success = response.message
                this.data = response.data
                this.makeCache()
            } catch (e: any) {
                this.errors.message = e.message || e.statusMessage
                this.errors.code = e.statusCode
            } finally {
                this.loading = false
            }
        },
        async getFlight(form: {flight_key: string}) {
            this.handleFlightLoading()
            this.flights = []
            try {
                const response = await useCustomFetch('/api/v1/flight/list/single', {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: form,
                }) as ApiResponse<FlightItem[]>
                if (!response.success) {
                    this.errors = {
                        message: response.message,
                        code: response.statusCode
                    }
                    return this.handleFlightLoading()
                }
                this.success = response.message
                this.flights = response.data
            } catch (e: any) {
                this.errors.message = e.message || e.statusMessage
                this.errors.code = e.statusCode
            } finally {
                this.flight_loading = false
            }
        },
        handleLoading() {
            this.loading = !this.loading
        },
        handleFlightLoading() {
            this.flight_loading = !this.flight_loading
        },
        makeCache() {
            const now = Date.now();
            this.cache_time = now + 5 * 60 * 1000;
        }
    },
})
