<script setup lang="ts">
import {flightPathBreadcrumb} from "~/utils/flight/flight-breadcrumb-utils";
import {useFlightListStore} from "~/stores/flight/list/useList";
import {storeToRefs} from "pinia";
import {
    getAirportFromSeo,
    handleRedirectFlight,
    hasValidQuery,
    makeFlightListForm
} from "~/utils/flight/flight-list-utils";
import {useFlightFilterStore} from "~/stores/flight/list/useFilter";
import type {SeoRelation} from "~/types/seo/SeoRelation";
import {
    getMeta,
    getOrganizationSchema,
    makeFaqs,
    setBreadcrumbSchema, setFaqSchema,
    setWebPageSchema,
    siteConfig
} from "~/utils/content/seo-utils";
import type {SeoItem} from "~/types/seo/SeoItem";
import {chevronLeftIcon, infoIcon} from "~/utils/icon-utils";
import type {FlightItem} from "~/types/flight/list/FlightItem";
import {formatHHii} from "~/utils/base/common-utils";
import {
    defaultCabinType, defaultDate, defaultPassengers,
    defaultTripType, generatePassengers,
    getCabinByCode,
    getFlightQuote, getFlightSearchDate,
    getFlightTypeByName,
    handleFlightSearch, makeSearch
} from "~/utils/flight/search-utils";


const deviceState = useState('deviceState', () => useDevice());
const isMobileOrTablet = computed(() => deviceState.value.isMobileOrTablet);
const isDesktop = computed(() => deviceState.value.isDesktop);
const route = useRoute()
const {slug} = route.params

const {data: content} = await useFetch('/api/v1/page', {
    key: `flight-${slug}-page`,
    headers: {
        'x-path-item': `flight/${slug}`,
        'Cache-Control': 'public, max-age=3600, stale-while-revalidate=86400'
    },
    transform: (input: any) => {
        // اجرای `getMeta()` در سرور
        return {
            ...input,
            fetchedAt: new Date()
        };
    },
    getCachedData: (key, nuxtApp) => {
        const cachedData = nuxtApp.payload.data[key] || nuxtApp.static.data[key];
        if (!cachedData) return;

        // اعتبارسنجی کش (۳۰ دقیقه)
        const isExpired = useCheckExpiration(cachedData.fetchedAt, 60);
        return isExpired ? undefined : cachedData;
    }
});

const isQueryValid = computed(() => hasValidQuery(route.query))

const links = ref(flightPathBreadcrumb({fa_name: content.value!.contents.title_fa as string, to: `/${slug}`}))

const listStore = useFlightListStore()
const {form, loading, flight_loading, flights} = storeToRefs(listStore);
const {getFlights, getFlight} = listStore
const filterStore = useFlightFilterStore()

const displayedFlights = ref(20);
const observer = ref<IntersectionObserver | null>(null);
const loadMoreTrigger = ref<HTMLElement | null>(null);
const intervalId = ref();
const isCacheExpired = ref(false);

loading.value = true

const getFlightsList = () => {
    const {date, return_date, adl, chd, inf, cabin_type, flight_type} = route.query;
    if (!content.value || !date) return;

    isCacheExpired.value = false;
    form.value = makeFlightListForm(
        content.value.seo_relations.find((a: SeoRelation) => a.relation_type === 'origin')?.model,
        content.value.seo_relations.find((a: SeoRelation) => a.relation_type === 'destination')?.model,
        date,
        getCabinByCode(cabin_type?.toString() || ''),
        getFlightTypeByName(flight_type?.toString() || ''),
    );
    getFlights();
}


const filteredFlights = computed(() => filterStore.filteredFlights);

const flightsToShow = computed(() =>
    filteredFlights.value.slice(0, displayedFlights.value)
);

const loadMoreFlights = () => {
    if (displayedFlights.value < filteredFlights.value.length) {
        displayedFlights.value += displayedFlights.value;
    }
};

const initObserver = () => {
    nextTick(() => {
        if (!loadMoreTrigger.value) {
            return;
        }


        observer.value = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    loadMoreFlights();
                }
            },
            {threshold: 0.1}
        );

        observer.value.observe(loadMoreTrigger.value);
    });
};
onMounted(() => {
    getFlightsList();
    initObserver();
});


const showOthers = ref(false)
const stars = ref(5)
const handleOthers = (flight: FlightItem) => {
    showOthers.value = true
    getFlight({flight_key: flight.flight_key})
}

const randomBannerPosition = computed(() => Math.floor(Math.random() * (flightsToShow.value.length - 1)));

getMeta(content.value as SeoItem)
const faqs = computed(() => content.value && content.value.faqs && content.value.faqs.length ? makeFaqs(content.value.faqs) : [])

useSchemaOrg([
    setBreadcrumbSchema([{name: siteConfig().site_persian_name, item: '/'}, {
        name: 'رزرو پرواز داخلی',
        item: '/flight'
    }, {name: `${content.value.title}`, item: route.path}]), // Breadcrumb
    setWebPageSchema(content.value?.title || 'Travel Search Engine', !!content.value?.faqs?.length), // WebPage
    getOrganizationSchema(), // اطلاعات سایت
    // getSearchActionSchema(), // قابلیت جستجو
    ...(setFaqSchema(content.value?.faqs) ?? [])
])

const handleRedirect = (flight: FlightItem) => handleRedirectFlight(flight)

const quoteMessage = computed(() => getFlightQuote({
    cabin_type: route.query.cabin_type as string,
    flight_type: route.query.flight_type as string
}));

const searchDate = computed(() => {
    const {date, return_date} = route.query;
    return getFlightSearchDate({date, return_date}, content.value.today.date as string)
});
const showAll = () => {
    const {adl, chd, inf} = route.query;
    const state = makeSearch(getAirportFromSeo(content.value), getAirportFromSeo(content.value, 'destination'), searchDate.value, defaultTripType(), null, defaultCabinType(), generatePassengers({
        adl: Number(adl),
        chd: Number(chd),
        inf: Number(inf)
    }));
    handleFlightSearch(state, false)
}
watch(route, () => getFlightsList())
</script>

<template>
    <nuxt-layout>
        <template #screenBox>
            <flight-list-top-box-component :seo="content"/>
        </template>
        <div class="w-full flex flex-col gap-12 justify-center mt-10">

            <div v-if="isQueryValid" class="w-full grid lg:grid-cols-4 gap-2">
                <div v-if="isDesktop" class="w-full col-span-1 p-4 relative">
                    <lazy-flight-list-body-sidebar-box-component/>
                </div>

                <div v-if="!isDesktop" class="flex items-center p-4">
                    <u-breadcrumb
                        :items="links"
                        :separator-icon="chevronLeftIcon()"
                        :ui="{link: 'text-primary-900'}"
                    />
                </div>

                <div class="w-full col-span-full md:col-span-3 p-4">
                    <div
                        class="w-full flex flex-col gap-8 h-full"
                    >
                        <div
                            v-show="quoteMessage && quoteMessage.message"
                            class="w-full"
                        >
                            <u-alert
                                color="info"
                                variant="soft"
                                :icon="infoIcon()"
                                :title="quoteMessage?.title"
                                :description="quoteMessage?.message"
                                :actions="[{ size: 'lg', icon: searchIcon(), variant: 'solid', color: 'primary', label: 'نمایش همه', onClick: () => showAll()}]"
                                :ui="{actions: 'justify-center lg:justify-end'}"
                            />
                        </div>
                        <template
                            v-if="!loading && flightsToShow.length"
                            v-for="(flight, index) in flightsToShow"
                            :key="index"
                        >
                            <div v-if="index === randomBannerPosition">
                                <lazy-common-action-partner-ship-component/>
                            </div>
                            <lazy-flight-list-body-item-component
                                :flight="flight"
                                @handle-others="handleOthers"
                                @handle-redirect="handleRedirect"
                            />
                        </template>
                        <div
                            ref="loadMoreTrigger"
                            v-show="filteredFlights.length > displayedFlights"
                            class="w-full h-10 flex justify-center items-center"
                        >
                            <span class="text-sm text-gray-500">در حال بارگذاری...</span>
                        </div>
                        <lazy-flight-list-body-skeleton-item-component
                            v-if="loading"
                            v-for="i in 10"
                            :key="i"
                        />
                        <div
                            v-if="!loading && (!filteredFlights || !filteredFlights.length)"
                            class="w-full flex items-center justify-center h-full"
                        >
                            <lazy-flight-list-body-no-flight-found-component
                                :seo="content"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <lazy-flight-list-body-no-valid-query-component
                v-else
                :seo="content"
            />
            <home-content-component
                v-if="isDesktop"
                :content="content.contents"
                :faqs="faqs"
                :anchors="content.anchors"
            />
        </div>
    </nuxt-layout>

    <lazy-u-modal
        v-model:open="showOthers"
        :ui="{content: 'sm:max-w-[600px] sm:min-h-[700px]'}"
        :fullscreen="!isDesktop"
        :dismissible="false"
    >
        <template #header>
            <div class="w-full flex ">
                <div v-if="!flight_loading" class="flex flex-col gap-1">
                    <h2 class="text-sm text-primary-950 tracking-tight font-semibold">سایر تامین کنندگان پرواز تهران
                        به مشهد</h2>
                    <div class="flex items-center gap-3">
                        <span
                            class="text-xs text-gray-600 tracking-tight">شماره پرواز: {{
                                flights[0].flight_number
                            }}</span>
                        <span
                            class="text-xs text-gray-600 tracking-tight">ساعت: {{
                                formatHHii(flights[0].departure_time.time)
                            }}</span>
                        <span
                            class="text-xs text-gray-600 tracking-tight">هواپیمایی: {{
                                flights[0].airline.name_fa
                            }}</span>
                    </div>
                </div>
                <div v-else class="flex flex-col gap-1">
                    <u-skeleton class="w-32 h-8"/>
                    <div class="flex items-center gap-3">
                        <u-skeleton class="w-16 h-4"/>
                        <u-skeleton class="w-16 h-4"/>
                        <u-skeleton class="w-16 h-4"/>
                    </div>
                </div>
            </div>
        </template>

        <template #body>
            <div
                class="w-full flex flex-col divide-y divide-primary-950 md:max-h-[500px] overflow-y-auto md:px-2.5 pb-20 md:pb-0">
                <div
                    v-if="!flight_loading"
                    v-for="(flight, i) in flights"
                    :key="i"
                    class="w-full flex justify-between items-center cursor-pointer"
                    @click="handleRedirect(flight)"
                >
                    <div class="flex gap-3 items-center">
                        <u-avatar
                            :alt="flight.agency.name_en"
                            size="lg"
                        />
                        <h3 class="text-xs text-primary-950 tracking-tight font-semibold">
                            {{ flight.agency.name_fa }}</h3>
                        <lazy-common-star-rate-component
                            v-model="stars"
                            :enable="false"
                        />
                    </div>
                    <div class="flex flex-col items-center gap-0.5">
                            <span
                                class="text-sm text-primary-900 tracking-tight font-semibold text-center">{{
                                    usePriceFormat(flight.price_details.adult)
                                }} <small>تومان</small></span>
                        <u-button
                            @click="handleRedirect(flight)"
                            label="رزرو"
                            :ui="{rounded: isDesktop ? 'rounded-full' : 'rounded-lg'}"
                            trailing-icon="i-ic-round-keyboard-arrow-left"
                            :class="!isDesktop ? 'w-full flex justify-between' : ''"
                        />
                    </div>
                </div>
                <div
                    v-if="flight_loading"
                    v-for="i in 6"
                    :key="i"
                    class="w-full flex justify-between items-center cursor-pointer p-4"
                >
                    <div class="flex gap-3 items-center">
                        <u-skeleton class="w-12 h-12 rounded-full"/>
                        <u-skeleton class="w-16 h-4 rounded-full"/>
                        <u-skeleton class="w-20 h-4 rounded-full"/>
                    </div>
                    <div class="flex flex-col items-center gap-0.5">
                        <u-skeleton class="w-16 h-4 rounded-full"/>
                        <u-skeleton class="w-20 h-8 rounded-full"/>
                    </div>
                </div>
            </div>
        </template>

        <template #footer>
            <div class="w-full flex items-center justify-between">
                <u-button
                    @click="showOthers = false"
                    color="error"
                    variant="outline"
                    label="تغییر انتخاب"
                    :ui="{rounded: 'rounded-full'}"
                    :trailing-icon="exchangeIcon()"
                    size="lg"
                />
                <u-button
                    @click="handleRedirect(flights[0])"
                    :disabled="flight_loading"
                    label="رزرو بهترین گزینه"
                    :ui="{rounded: 'rounded-full'}"
                    :trailing-icon="chevronLeftIcon()"
                    size="lg"
                />
            </div>
        </template>
    </lazy-u-modal>
</template>
