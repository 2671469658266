<script setup lang="ts">

import type {SeoItem} from "~/types/seo/SeoItem";
import {formatPersianDate, getToday} from "~/utils/calendar/persian-utils";
import {getPassengerCountFromQuery} from "~/utils/flight/passenger/PassengerTypeCount";
import {searchIcon} from "~/utils/icon-utils";

const props = defineProps<{
    seo: SeoItem
}>()

const deviceState = useState('deviceState', () => useDevice());
const isMobileOrTablet = computed(() => deviceState.value.isMobileOrTablet);
const isDesktop = computed(() => deviceState.value.isDesktop);

const route = useRoute()
const {date, return_date} = route.query

const passengersCount = computed(() => getPassengerCountFromQuery(route.query))
</script>

<template>
  <div
      class="w-full flex md:justify-center items-center gap-1.5 md:gap-6 relative"
  >
    <div class="flex gap-2 text-white items-center">
      <u-icon
          name="i-bi-airplane-engines"
          class="w-4 h-4 md:w-6 md:h-6"
      />
      <span class="text-xs md:text-sm tracking-tight font-normal">{{seo.contents.title_fa}}</span>
    </div>
    <span v-if="!isDesktop" class="text-white text-xs">-</span>
    <div class="flex gap-2 text-white items-center">
      <u-icon
          name="i-material-symbols-event"
          class="hidden md:block w-6 h-6"
      />
      <span class="text-xs md:text-sm tracking-tight font-normal">{{formatPersianDate(date ? date.toString() : getToday(), 'jD jMMM') }}</span>
    </div>
    <span v-if="!isDesktop" class="text-white text-xs">-</span>
    <div class="flex gap-2 text-white items-center">
      <u-icon
          name="i-mdi-seat-passenger"
          class="hidden md:block w-6 h-6"
      />
      <span class="text-xs md:text-sm tracking-tight font-normal">{{passengersCount.totalCount}} مسافر</span>
    </div>
    <div class="flex gap-2 text-white items-center"
         :class="!isDesktop ? 'absolute left-0' : ''"
    >
      <u-button
          color="neutral"
          size="xs"
          variant="outline"
          :square="!isDesktop"
          :label="isDesktop ? 'تغییر جستجو' : ''"
          :icon="searchIcon()"
          class="w-full flex justify-center"
          :ui="{base: 'rounded-full py-2 px-4'}"
      />
    </div>
  </div>
</template>
