<script setup lang="ts">
import {
    defaultCabinType,
    defaultDate,
    defaultPassengers,
    defaultTripType,
    handleFlightSearch
} from "~/utils/flight/search-utils";
import {flightPathBreadcrumb} from "~/utils/flight/flight-breadcrumb-utils";
import type {SeoItem} from "~/types/seo/SeoItem";
import {getAirportFromSeo} from "~/utils/flight/flight-list-utils";
import {chevronLeftIcon, chevronUpIcon, longArrowRight} from "~/utils/icon-utils";
import type {FlightSearchForm} from "~/types/flight/search/FlightSearchForm";
type modalType = 'origin' | 'destination' | 'date' | 'passengers' | null;

const props = defineProps<{
    seo: SeoItem
}>()

const route = useRoute()
const {slug} = route.params

const links = ref(flightPathBreadcrumb({fa_name: props.seo.contents.title_fa as string, to: `/${slug}`}))

const state = reactive({
    origin: getAirportFromSeo(props.seo),
    destination: getAirportFromSeo(props.seo, 'destination'),
    tripType: defaultTripType(),
    cabinType: defaultCabinType(),
    date: defaultDate(),
    passengers: defaultPassengers()
})

const deviceState = useState('deviceState', () => useDevice());
const isMobileOrTablet = computed(() => deviceState.value.isMobileOrTablet);
const isDesktop = computed(() => deviceState.value.isDesktop);

const showSearch = ref(false)
const open = ref(false)
const showFilters = ref(false)
const activeType = ref<modalType>(null)

const handleShowSearch = () => {
    if (isDesktop.value) showSearch.value = !showSearch.value
    else handleOver('origin')
}

const handleOver = (type: modalType = null) => {
    activeType.value = type
    open.value = false
    setTimeout(() => {
        if (activeType.value)
            open.value = true;
    }, 150)
}

const {scrollingDirection, scrollingStopped} = useScrollDirection()

const handleFilters = () => showFilters.value = !showFilters.value

const scroll = useScroll(20)

const handleSearch = (data: FlightSearchForm) => {
    handleOver()
    handleFlightSearch(data, false)
}

watch(() => scrollingStopped.value, () => {
    if (scrollingStopped.value) showSearch.value = false
})
</script>

<template>
    <div
        class="w-full  flex flex-col bg-primary transition-all duration-[2000ms] ease-in-out"
        :class="{
    'fixed top-0 z-20': !isDesktop,
    'opacity-20': !scrollingStopped,
    'opacity-100': scrollingStopped,
    'lg:min-h-[200px]': !scroll
  }"
    >
        <div
            class="main-width w-full mx-auto flex flex-col p-4 gap-5"
        >
            <transition name="smooth-show">
                <div
                    v-show="!scroll"
                    class="w-full flex items-center justify-between transition-all duration-[2000ms]"
                >
                    <div class="w-full flex flex-col gap-2 md:pt-10">
                        <h1 class="md:text-2xl text-white tracking-tight font-semibold">{{ seo.contents.title_fa }}</h1>
                        <span
                            v-show="isDesktop"
                            class="text-xs md:text-sm text-white tracking-tight md:mr-2">خرید و رزرو پرواز های {{ getAirportFromSeo(seo).name_fa }} به {{ getAirportFromSeo(seo, 'destination').name_fa }}</span>
                    </div>
                    <div v-if="isDesktop" class="flex justify-end items-center">
                        <u-breadcrumb
                            :items="links"
                            :separator-icon="chevronLeftIcon()"
                            :ui="{link: 'text-white'}"
                        />
                    </div>

                    <div class="flex justify-end items-center">
                        <u-button
                            @click="handleFilters"
                            v-if="!isDesktop"
                            color="neutral"
                            variant="outline"
                            size="xs"
                            square
                            icon="i-quill-hamburger-sidebar"
                            class="w-full flex justify-center"
                            :ui="{base: 'rounded-full'}"
                        />
                    </div>
                </div>
            </transition>

            <div v-if="showSearch || open" class="flex flex-col py-10 px-5 gap-5 transition-all duration-200">
                <div v-if="isDesktop && showSearch" class="w-full flex justify-between items-center gap-3">
                    <div class="flex items-center gap-3">
                        <div class="w-fit flex border-b border-black">
                            <lazy-flight-search-trip-type-component
                                has-route
                                v-model="state.tripType"
                            />
                        </div>
                        <div>
                            <lazy-flight-search-cabin-type-component
                                has-route
                                v-model="state.cabinType"
                            />
                        </div>
                    </div>

                    <u-button
                        @click="handleShowSearch"
                        color="white"
                        variant="soft"
                        label="بستن"
                        class="text-white"
                        :leading-icon="chevronUpIcon()"
                        :ui="{base: 'text-white hover:text-white', rounded: 'rounded-full'}"
                    />
                </div>
                <lazy-flight-search-box-component
                    v-if="isDesktop && showSearch"
                    has-route
                    :trip-type="state.tripType"
                    :origin="state.origin"
                    :destination="state.destination"
                    @handle-search="(s: FlightSearchForm) => handleFlightSearch(s, false)"
                />
                <lazy-flight-search-small-over-component
                    v-if="open && !isDesktop"
                    :open-over="open"
                    type="origin"
                    :search="state"
                    @handle-search="handleSearch"
                />
            </div>
            <div
                v-else
                class="flex"
                :class="scrollingDirection !== 'up' ? 'py-2' : ''"
                @click="handleShowSearch"
            >
                <flight-search-short-component
                    :seo="seo"
                />
            </div>
        </div>
    </div>

    <u-slideover
        v-model:open="showFilters"
        :transition="true"
        :ui="{background: 'bg-white'}"
        side="left"
    >
        <template #content>
            <lazy-flight-list-body-sidebar-box-component @close="handleFilters"/>
        </template>
    </u-slideover>
</template>

<style>
/* Smooth-show transition */
.smooth-show-enter-active, .smooth-show-leave-active {
    transition: opacity 0.5s, transform 0.5s;
}

.smooth-show-enter-from, .smooth-show-leave-to {
    opacity: 0;
    transform: translateY(-20px);
}

.smooth-show-leave-from, .smooth-show-enter-to {
    opacity: 1;
    transform: translateY(0);
}
</style>
