import type {LocationQuery, LocationQueryValue} from "#vue-router";
import {convertPersianToGregorian} from "~/utils/calendar/persian-utils";
import type {FlightListForm} from "~/types/flight/list/FlightListForm";
import type {CityAirport} from "~/types/flight/city/CityAirport";
import type {SeoItem} from "~/types/seo/SeoItem";
import type {SeoRelation} from "~/types/seo/SeoRelation";
import type {FlightItem} from "~/types/flight/list/FlightItem";
import {useFlightRedirectStore} from "~/stores/flight/list/useRedirect";
import {storeToRefs} from "pinia";
import type {AirlineItem} from "~/types/flight/list/AirlineItem";
import type {CabinType} from "~/types/flight/search/CabinType";
import type {FlightTypeItem} from "~/types/flight/FlightTypeItem";

export const makeFlightListForm = (origin: CityAirport, destination: CityAirport, date: string |  LocationQueryValue[], cabin_type: CabinType | null = null, flight_type: FlightTypeItem | null = null/*, adlCount: number, chdCount: number, infCount: number, return_date: string | LocationQueryValue | LocationQueryValue[] = ''*/): FlightListForm => {
    return {
        origin: origin.iata_code,
        destination: destination.iata_code,
        date: convertPersianToGregorian(date.toString()),
        trip_type: "one_way",
        cabin_type: cabin_type && cabin_type.name !== 'all' ? cabin_type.name : '',
        flight_type: flight_type?.name_en || ''
        // return_date: return_date ? convertPersianToGregorian(return_date.toString()) : '',
        // passengers: generatePassengers({adl: adlCount, chd: chdCount, inf: infCount})
    }
}

export const hasValidQuery = (query: LocationQuery) => {
    return Boolean(query.date);
}

export const getAirportFromSeo = (seo: SeoItem, type: 'origin' | 'destination' = 'origin'): CityAirport => seo.seo_relations.find((a: SeoRelation) => a.relation_type === type)!.model as CityAirport
export const getTitle = (seo: SeoItem) => {
    const origin = getAirportFromSeo(seo)
    const destination = getAirportFromSeo(seo, 'destination')
    return `رزرو بلیط هواپیما ${origin.city?.name_fa} ${destination.city?.name_fa}`
}

export const handleRedirectFlight = (flight: FlightItem) => {
    const redirectStore = useFlightRedirectStore()
    const {data: redirectFlight} = storeToRefs(redirectStore)
    redirectFlight.value = { ...flight };
    return navigateTo({
        path: `/flight/redirect`,
        replace: false,
    }, {
        open: {
            target: '_blank',
        },
    })
}

export function getUniqueAirlinesSortedByPrice(
    data: FlightItem[],
    filterType: 'oneWay' | 'roundTrip' = 'oneWay'
): { airline: AirlineItem; price: number, count: number }[] {
    if (!data || !data.length) return []; // Handle empty or undefined data

    const airlineCountMap = new Map<string, number>();

    data.forEach((flight: FlightItem) => {
        const airlineCode = flight.airline.iata_code;
        if (airlineCode) {
            airlineCountMap.set(airlineCode, (airlineCountMap.get(airlineCode) || 0) + 1);
        }
    });


    // Sort flights by price
    const sortedFlights = data.sort((a, b) => {
        const priceA = filterType === 'oneWay'
            ? parseFloat(a.price_details.adult.toString() || 'Infinity')
            : parseFloat(a.price_details.adult.toString() || 'Infinity');
        const priceB = filterType === 'oneWay'
            ? parseFloat(b.price_details.adult.toString() || 'Infinity')
            : parseFloat(b.price_details.adult.toString() || 'Infinity');
        return priceA - priceB;
    });

    // Use a Set to track unique airline codes
    const uniqueAirlines = new Set<string>();

    // Filter and map the sorted flights to extract unique airlines with minimum prices
    return sortedFlights
        .filter((flight) => {
            const airlineCode = filterType === 'oneWay'
                ? flight.airline.iata_code
                : flight.airline.iata_code;

            if (!airlineCode || uniqueAirlines.has(airlineCode)) {
                return false; // Skip if airline code is already added
            }
            uniqueAirlines.add(airlineCode);
            return true;
        })
        .map((flight) => {
            const airline = filterType === 'oneWay'
                ? flight.airline
                : flight.airline;
            const price = filterType === 'oneWay'
                ? parseFloat(flight.price_details.adult.toString() || 'Infinity')
                : parseFloat(flight.price_details.adult.toString() || 'Infinity');
            const count = airlineCountMap.get(flight.airline.iata_code) || 0;
            return { airline: airline as AirlineItem, price, count};
        });
}
