import { defineStore, storeToRefs } from "pinia";
import type {FlightItem} from "~/types/flight/list/FlightItem";
import {useFlightListStore} from "~/stores/flight/list/useList";
import type {FlightFilterState} from "~/types/flight/list/filter/FlightFilterState";
import type {FlightFilters} from "~/types/flight/list/filter/FlightFilters";
import {formatHHii} from "~/utils/base/common-utils";


export const useFlightFilterStore = defineStore("flightFilter", {
    state: (): FlightFilterState => ({
        oneWay: {
            departureTime: [],
            arrivalTime: [],
            type: null,
            airline: [],
            sort: 4,
        },
        roundTrip: {
            departureTime: [],
            arrivalTime: [],
            type: null,
            airline: [],
            sort: 4,
        },
        activeFilterType: "oneWay",
    }),
    actions: {
        getActiveFilter(): FlightFilters {
            return this.activeFilterType === "oneWay" ? this.oneWay : this.roundTrip;
        },
        setSortCriteria(criteria: 1 | 2 | 3 | 4) {
            this.getActiveFilter().sort = criteria;
        },
        updateActiveFilterType(type: "oneWay" | "roundTrip") {
            this.activeFilterType = type;
        },
        updateDepartureTime(minTime: string, maxTime: string) {
            const activeFilter = this.getActiveFilter();
            const existingIndex = activeFilter.departureTime.findIndex(
                ([start, end]) => start === minTime && end === maxTime
            );
            if (existingIndex === -1) {
                activeFilter.departureTime.push([minTime, maxTime]);
            } else {
                activeFilter.departureTime.splice(existingIndex, 1);
            }
        },
        updateArrivalTime(minTime: string, maxTime: string) {
            const activeFilter = this.getActiveFilter();
            const existingIndex = activeFilter.arrivalTime.findIndex(
                ([start, end]) => start === minTime && end === maxTime
            );
            if (existingIndex === -1) {
                activeFilter.arrivalTime.push([minTime, maxTime]);
            } else {
                activeFilter.arrivalTime.splice(existingIndex, 1);
            }
        },
        setTypeFilter(type: "system" | "charter") {
            const activeFilter = this.getActiveFilter();
            activeFilter.type = activeFilter.type === type ? null : type;
        },
        updateAirlineFilter(airlineCode: string) {
            const activeFilter = this.getActiveFilter();
            const index = activeFilter.airline.indexOf(airlineCode);
            if (index === -1) {
                activeFilter.airline.push(airlineCode);
            } else {
                activeFilter.airline.splice(index, 1);
            }
        },
        resetFilters() {
            this.oneWay = { departureTime: [], arrivalTime: [], type: null, airline: [], sort: 4 };
            this.roundTrip = { departureTime: [], arrivalTime: [], type: null, airline: [], sort: 4 };
        },
    },
    getters: {
        filteredFlights(state): FlightItem[] {
            const flightListStore = useFlightListStore();
            const { data: flights } = storeToRefs(flightListStore);
            if (!flights.value) return [];

            const filtered =  flights.value.filter((flight) => {
                const matchesOneWay = state.oneWay.departureTime.length === 0 ||
                    state.oneWay.departureTime.some(([start, end]) =>
                        formatHHii(flight.departure_time.time) >= start && formatHHii(flight.departure_time.time) <= end
                    );

                const matchesOneWayArrival = state.oneWay.arrivalTime.length === 0 ||
                    state.oneWay.arrivalTime.some(([start, end]) =>
                        formatHHii(flight.arrival_time.time) >= start && formatHHii(flight.arrival_time.time) <= end
                    );
                // const matchesRoundTrip =
                //     state.roundTrip.departureTime.length === 0 ||
                //     state.roundTrip.departureTime.some(([start, end]) =>
                //         flight.flights.round?.time
                //             ? flight.flights.round.time >= start && flight.flights.round.time <= end
                //             : false
                //     );


                const matchesType =
                    (!state.oneWay.type || flight.is_charter.code && state.oneWay.type === 'charter') /*&&
                    (!state.roundTrip.type || flight.flights.round?.type.name === state.roundTrip.type);*/

                const matchesAirline =
                    (state.oneWay.airline.length === 0 ||
                        state.oneWay.airline.includes(flight.airline.iata_code || "")) /*&&
                    (state.roundTrip.airline.length === 0 ||
                        state.roundTrip.airline.includes(flight.flights.round?.airline.code || ""));*/

                return matchesOneWay && matchesOneWayArrival && /*matchesRoundTrip &&*/ matchesType && matchesAirline;
            });

            const activeFilter = state.activeFilterType === "oneWay" ? state.oneWay : state.roundTrip;
            const sort = activeFilter.sort;
            return filtered.sort((a, b) => {
                const priceA = Number(a.price_details.adult || 0); // Ensure price is a number
                const priceB = Number(b.price_details.adult || 0); // Ensure price is a number
                const timeA = formatHHii(a.departure_time.time) || "00:00";
                const timeB = formatHHii(b.departure_time.time) || "00:00";

                if (sort === 1) {
                    return this.compareTime(timeA, timeB); // Earliest
                } else if (sort === 2) {
                    return this.compareTime(timeB, timeA); // Latest
                } else if (sort === 3) {
                    return priceB - priceA; // Highest price
                } else if (sort === 4) {
                    return priceA - priceB; // Lowest price
                }
                return 0;
            });
            /*const filtered =  flights.value.filter((flight) => {
                const matchesOneWay = state.oneWay.departureTime.length === 0 ||
                    state.oneWay.departureTime.some(([start, end]) =>
                        flight.flights.trip?.time >= start && flight.flights.trip?.time <= end
                    );

                const matchesRoundTrip =
                    state.roundTrip.departureTime.length === 0 ||
                    state.roundTrip.departureTime.some(([start, end]) =>
                        flight.flights.round?.time
                            ? flight.flights.round.time >= start && flight.flights.round.time <= end
                            : false
                    );


                const matchesType =
                    (!state.oneWay.type || flight.flights.trip?.type.name === state.oneWay.type) &&
                    (!state.roundTrip.type || flight.flights.round?.type.name === state.roundTrip.type);

                const matchesAirline =
                    (state.oneWay.airline.length === 0 ||
                        state.oneWay.airline.includes(flight.flights.trip?.airline.code || "")) &&
                    (state.roundTrip.airline.length === 0 ||
                        state.roundTrip.airline.includes(flight.flights.round?.airline.code || ""));

                return matchesOneWay && matchesRoundTrip && matchesType && matchesAirline;
            });

            const activeFilter = state.activeFilterType === "oneWay" ? state.oneWay : state.roundTrip;
            const sort = activeFilter.sort;
            return filtered.sort((a, b) => {
                const priceA = Number(a.flights.trip?.price || 0); // Ensure price is a number
                const priceB = Number(b.flights.trip?.price || 0); // Ensure price is a number
                const timeA = a.flights.trip?.time || "00:00";
                const timeB = b.flights.trip?.time || "00:00";

                if (sort === 1) {
                    return this.compareTime(timeA, timeB); // Earliest
                } else if (sort === 2) {
                    return this.compareTime(timeB, timeA); // Latest
                } else if (sort === 3) {
                    return priceB - priceA; // Highest price
                } else if (sort === 4) {
                    return priceA - priceB; // Lowest price
                }
                return 0;
            });*/
        },

        compareTime: () => (timeA: string, timeB: string) => {
            const [hoursA, minutesA] = timeA.split(':').map(Number);
            const [hoursB, minutesB] = timeB.split(':').map(Number);

            if (hoursA !== hoursB) {
                return hoursA - hoursB;
            }

            return minutesA - minutesB;
        },
    },
});
